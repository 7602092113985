import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from 'core/api/services/api.service';
import { CustomerModel, PaymentMethodModel } from '../customer.model';
import { UserApiService } from 'features/user/api/api-tokens';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  constructor(
    @Inject(UserApiService) protected apiService: ApiService,
  ) {}

  public getCustomers(): Observable<CustomerModel[]> {
    return this.apiService.get<CustomerModel[]>('customersListCustomer').pipe(
      map((customers) => {
        // do required customizations
        return customers;
      })
    );
  }

  public getCustomer(customerId: string): Observable<CustomerModel> {
    return this.apiService.get<CustomerModel>('customersGetCustomer', {
      urlParams: {
        customerId: customerId
      }
    }).pipe(
      map((customers) => {
        // do required customizations
        return customers;
      })
    );
  }

  // api to read payment methods
  public getPaymentMethods(customerId: string): Observable<PaymentMethodModel[]> {
    return this.apiService.get<PaymentMethodModel[]>('customersPaymentMethods', {
      urlParams: {
        customerId: customerId
      }
    }).pipe(
      map((paymentMethods) => {

        paymentMethods = paymentMethods.map((paymentMethod: PaymentMethodModel) => {
          paymentMethod.createdDateTime = new Date(paymentMethod.createdDateTime ?? '');
          paymentMethod.updateDateTime = new Date(paymentMethod.updateDateTime ?? '');
          return paymentMethod;
        });

        return paymentMethods;
      })
    );
  }
  public createCustomer(customer: CustomerModel): Observable<CustomerModel> {
    return this.apiService.post<CustomerModel>('customersCreateCustomer', {
      body: customer
    });
  }

  public deleteCustomer(customerId: string): Observable<CustomerModel> {
    return this.apiService.delete<CustomerModel>('customersDeleteCustomer', {
      urlParams: {
        customerId: customerId
      },
    });
  }

  public createPaymentMethod(customerId: string, paymentMethod: PaymentMethodModel): Observable<CustomerModel> {
    return this.apiService.post<PaymentMethodModel>('customersCreatePaymentMethod', {
      urlParams: {
        customerId: customerId
      },
      body: paymentMethod
    });
  }

  public deletePaymentMethod(customerId: string, paymentMethodId: string): Observable<CustomerModel> {
    return this.apiService.delete<PaymentMethodModel>('customersDeletePaymentMethod', {
      urlParams: {
        customerId: customerId,
        paymentMethodId: paymentMethodId
      }
    });
  }

  public updateCustomer(customerId: string, customer: CustomerModel): Observable<CustomerModel> {
    return this.apiService.put<CustomerModel>('customersUpdateCustomer', {
      urlParams: {
        customerId: customerId
      },
      body: customer
    });
  }

}
