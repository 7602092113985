import { getState, patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { effect, inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';
import { omit } from 'lodash-es';

import { CustomerModel, PaymentMethodModel } from '../../customer/customer.model';
import { InvoiceDetailsInterface } from '../payment.model';
import { Transaction } from '../../transactions/transaction.model';
import { CustomersService } from '../../customer/services/customers.service';
import { NotificationService } from '../../../shared/services/notification.service';


export const PaymentCollectStoreId = '__payment-collect';
type PaymentCollectState = {
  customer: CustomerModel | null,
  invoice: InvoiceDetailsInterface | null,
  payment: PaymentMethodModel | null,
  transaction: Transaction | null,
  data: {
    customers: CustomerModel[],
    paymentMethods: PaymentMethodModel[],
  }
};

//const data: PaymentCollectState = JSON.parse(localStorage.getItem(PaymentCollectStoreId) ?? 'null');
const data: PaymentCollectState = {
  customer: null,
  payment: null,
  invoice: null,
  transaction: null,
  data: {
    customers: [],
    paymentMethods: []
  }
};
const initialState: PaymentCollectState = data;

export const PaymentCollectStore = signalStore(
  { providedIn: 'root', protectedState: false },
  withState(initialState),

  withMethods((store) => {
    const customerApiService = inject(CustomersService);
    const notificationService = inject(NotificationService);
    return ({
      reset(): void {
        patchState(store, {
          customer: null,
          payment: null,
          invoice: null
        });
      },
      addCustomer(customer: CustomerModel): void {
        patchState(store, {
          customer: customer
        });
      },
      addPayment(payment: PaymentMethodModel): void {
        payment = omit(payment, [ 'isDefaultFormControl' ]);
        patchState(store, {
          payment: payment
        });
      },
      addInvoice(invoice: InvoiceDetailsInterface): void {
        patchState(store, {
          invoice: invoice
        });
      },
      addTransaction(transaction: Transaction): void {
        patchState(store, {
          transaction: transaction
        });
      },
      loadCustomers: rxMethod<void>(
        pipe(
          switchMap(() => customerApiService.getCustomers()
            .pipe(
              tapResponse({
                next: (customers) => {
                  patchState(store, { data: { ...store.data(), customers: customers } });
                },
                error: (error: Error) => {
                  notificationService.error(error.message)
                }
              }),
            )
          )
        )
      ),
      loadPaymentMethods: rxMethod<string>(
        pipe(
          switchMap((customerId) => customerApiService.getPaymentMethods(customerId)
            .pipe(
              tapResponse({
                next: (paymentMethods) => {
                  patchState(store, { data: { ...store.data(), paymentMethods: paymentMethods } });
                },
                error: (error: Error) => {
                  notificationService.error(error.message)
                }
              })
            )
          )
        )
      ),
      reloadCustomers() {
        this.loadCustomers();
      },
      reloadPaymentMethods() {
        const customer = store.customer();
        if (customer?.id) {
          this.loadPaymentMethods(customer.id);
        }
      },
    });
  }),
  withHooks({
    onInit(store) {
      store.loadCustomers();

      effect(() => {
        const customer = store.customer();
        if (customer?.id) {
          store.loadPaymentMethods(customer.id);
        }
      });

      effect(() => {
        // 👇 The effect is re-executed on state change.
        const state = getState(store);
        localStorage.setItem(PaymentCollectStoreId, JSON.stringify(state));
      });

    },
  })
);
