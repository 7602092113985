import { Injector } from '@angular/core';
import { ModalComponentInterface, ModalConfig, ModalService } from '../../../shared/components/modal/modal.service';
import { GridColumnActionType } from '../../../shared/components/grid/grid-config';
import { CustomerModel } from '../customer.model';
import { CustomerDetailsFormComponent } from '../components/customer-details-form/customer-details-form.component';
import { ActionsService } from '../services/actions.service';
import { ModalComponent } from 'shared/components/modal/modal/modal.component';

enum CustomerActionNames {
  'viewOrEdit' = 'viewOrEdit',
  'delete' = 'delete'
}

const openModal = <T>(injector: Injector, data: ModalConfig<T>): void => {
  const modalService = injector!.get(ModalService);
  modalService.openModal<T>(data);
}

const customerActionHandlers: { [key in CustomerActionNames]: GridColumnActionType<CustomerModel> } = {
  [CustomerActionNames.viewOrEdit]: {
    label: 'View or Edit',
    handler: (customer: CustomerModel, component: Object, injector: Injector) => {
      const actionsService = injector.get(ActionsService);
      openModal<CustomerDetailsFormComponent>(injector, {
        title: 'Customer Information',
        body: CustomerDetailsFormComponent,
        data: {
          customerId: customer.id
        },
        confirm: {
          label: 'Save',
          action: (modal: ModalComponentInterface, customerFormComponent: CustomerDetailsFormComponent) => {
            const form = customerFormComponent!.form;
            if (!form.valid) {
              form.markAllAsTouched();
              return;
            }
            const formData = form.getRawValue();
            actionsService.handleCustomerSaveAction(
              customer,
              formData,
              () => modal.close()
            );
          },
        },
        dismiss: (modal: ModalComponentInterface) => {
          modal.close();
        }
      });
    }
  },
  [CustomerActionNames.delete]: {
    label: 'Delete',
    handler: (customer: CustomerModel, component: Object, injector: Injector) => {
      const actionsService = injector.get(ActionsService);
      openModal(injector!, {
        title: 'Delete Customer',
        body: `Are you sure you want to delete customer '${ customer.contactEmail }'?`,
        dismiss: (modal: ModalComponentInterface) => {
          modal.close();
        },
        confirm: {
          label: 'Save',
          action: (modal: ModalComponentInterface) => {
            actionsService.handleCustomerDeleteAction(
              customer,
              () => modal.close()
            );
          }
        }
      });
    },
  }
}

export const customerActions = [
  customerActionHandlers[CustomerActionNames.viewOrEdit],
  customerActionHandlers[CustomerActionNames.delete],
];
