import { AfterViewInit, Component, DestroyRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { CustomersService } from '../../services/customers.service';
import { CustomerModel } from '../../customer.model';
import { NgxMatErrors } from 'ngx-mat-errors';

export type CustomerDetailsFormType = FormGroup<{
  contactFirstName: FormControl<string | null>,
  contactLastName: FormControl<string | null>,
  displayName: FormControl<string | null>,
  companyName: FormControl<string | null>,
  companyAccountNumber: FormControl<string | null>,
  businessPhone: FormControl<string | null>,
  businessPhoneExt: FormControl<string | null>,
  mobilePhoneNumber: FormControl<string | null>,
  contactEmail: FormControl<string | null>,
  notes: FormControl<string | null>,
}>;

@Component({
  selector: 'app-customer-details-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatLabel,
    MatInputModule,
    NgxMatErrors
  ],
  templateUrl: './customer-details-form.component.html',
  host: { class: 'app-customer-details-form' }
})
export class CustomerDetailsFormComponent implements AfterViewInit, OnDestroy {
  form: CustomerDetailsFormType = new FormGroup({
    contactFirstName: new FormControl('', Validators.required),
    contactLastName: new FormControl('', Validators.required),
    displayName: new FormControl(''),
    companyName: new FormControl('', Validators.required),
    companyAccountNumber: new FormControl('', Validators.required),
    businessPhone: new FormControl(''),
    businessPhoneExt: new FormControl(''),
    mobilePhoneNumber: new FormControl(''),
    contactEmail: new FormControl('', Validators.required),
    notes: new FormControl(''),
  });

  readonly data?: {
    customerId: string;
  };

  constructor(
    protected customersApiService: CustomersService,
    protected destroyRef: DestroyRef
  ) {
  }

  ngAfterViewInit(): void {
    if (this.data!.customerId) {
      this.customersApiService
        .getCustomer(this.data!.customerId)
        .pipe(
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(
          (customer: CustomerModel) => {
            return this.form.patchValue(customer);
          }
        );
    }
  }

  ngOnDestroy(): void {
  }
}
