<div [formGroup]="form" class="container">
  <div class="row">
    <div class="col-6">
      <div class="form-control">
        <mat-form-field appearance="outline">
          <mat-label>Company name</mat-label>
          <input matInput formControlName="companyName">
          <mat-error ngx-mat-errors />
        </mat-form-field>
      </div>
      <div class="form-control">
        <mat-form-field appearance="outline">
          <mat-label>Display Name</mat-label>
          <input matInput formControlName="displayName">
          <mat-error>
            @if (form.controls.companyName.hasError('required')) {
              Display name is required
            }
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-control">
        <mat-form-field appearance="outline">
          <mat-label>Account Number</mat-label>
          <input matInput formControlName="companyAccountNumber">
          <mat-error>
            @if (form.controls.companyName.hasError('required')) {
              Account name is required
            }
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-control form-control-no-margin">
        <mat-form-field appearance="outline">
          <mat-label>Email Address</mat-label>
          <input matInput formControlName="contactEmail">
          <mat-error>
            @if (form.controls.contactEmail.hasError('required')) {
              Email Address is required
            }
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-6">
      <div class="form-control">
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="contactLastName">
          <mat-error>
            @if (form.controls.contactLastName.hasError('required')) {
              Last name is required
            }
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-control">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="contactFirstName">
          <mat-error>
            @if (form.controls.contactFirstName.hasError('required')) {
              First name is required
            }
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-control-group form-control-group-compact">
        <div class="form-control grow-2">
          <mat-form-field appearance="outline">
            <mat-label>Business Phone</mat-label>
            <input matInput formControlName="businessPhone">
            <mat-error>
              @if (form.controls.businessPhone.hasError('required')) {
                Business phone is required
              }
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-control grow-0 shrink-2">
          <mat-form-field appearance="outline">
            <mat-label>Extension</mat-label>
            <input matInput formControlName="businessPhoneExt">
            <mat-error>
              @if (form.controls.businessPhone.hasError('required')) {
                Phone extension is required
              }
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-control form-control-no-margin">
        <mat-form-field appearance="outline">
          <mat-label>Mobile Phone</mat-label>
          <input matInput formControlName="mobilePhoneNumber">
          <mat-error>
            @if (form.controls.businessPhone.hasError('required')) {
              Mobile phone is required
            }
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-control-group">
        <div class="form-control">
          <mat-form-field appearance="outline">
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="notes"></textarea>
            <mat-error>
              @if (form.controls.businessPhone.hasError('required')) {
                Notes are required
              }
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
