import { DestroyRef, inject, Injectable } from '@angular/core';
import { OverlayService } from '../../../shared/services/overlay.service';
import { CustomersService } from './customers.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { CustomerModel, PaymentMethodModel } from '../customer.model';
import { tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PaymentCollectStore } from '../../payment/store/payment-collect.store';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  protected overlayService = inject(OverlayService);
  protected customerService = inject(CustomersService);
  protected notificationService = inject(NotificationService);
  protected destroyRef = inject(DestroyRef);
  protected store = inject(PaymentCollectStore);

  public handleCustomerSaveAction(
    customer: CustomerModel,
    formData: any,
    successCallback?: () => void,
  ): void {

    const customerModel: CustomerModel = {
      id: customer.id,
      contactFirstName: <string>formData!.contactFirstName,
      contactLastName: <string>formData!.contactLastName,
      displayName: <string>formData!.displayName,
      companyName: <string>formData!.companyName,
      companyAccountNumber: <string>formData!.companyAccountNumber,
      contactEmail: <string>formData!.contactEmail,
      contactPhoneNumber: <string>formData!.businessPhone + ' ' + <string>formData!.businessPhoneExt,
      mobilePhoneNumber: <string>formData!.mobilePhoneNumber,
      notes: <string>formData!.notes,
    };

    this.customerService.updateCustomer(<string>customer.id, customerModel)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.overlayService.showOverlay())
      )
      .subscribe({
        next: (customer) => {
          if (successCallback instanceof Function) {
            successCallback();
          }
          this.overlayService.hideOverlay();
          this.notificationService.success('Customer updated successfully');
        },
        error: (error) => {
          this.overlayService.hideOverlay()
          this.notificationService.error(error.message);
        }
      });
  }

  public handleCustomerDeleteAction(
    customer: CustomerModel,
    successCallback?: () => void,
  ): void {
    this.customerService
      .deleteCustomer(<string>customer.id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.overlayService.showOverlay())
      )
      .subscribe({
        next: (customer) => {
          this.store.reloadCustomers();
          if (successCallback instanceof Function) {
            successCallback();
          }
          this.overlayService.hideOverlay();
          this.notificationService.success('Customer has been deleted successfully');

        },
        error: (error) => {
          this.overlayService.hideOverlay()
          this.notificationService.error(error.message);
        }
      });
  }

  public handlePaymentDeleteAction(
    paymentMethod: PaymentMethodModel,
    successCallback?: () => void,
  ): void {
    this.customerService
      .deletePaymentMethod(<string>paymentMethod!.customerId, <string>paymentMethod!.id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.overlayService.showOverlay())
      )
      .subscribe({
        next: () => {
          this.store.reloadPaymentMethods();
          if (successCallback instanceof Function) {
            successCallback();
          }
          this.overlayService.hideOverlay();
          this.notificationService.success('Payment has been deleted successfully');
        },
        error: (error) => {
          this.overlayService.hideOverlay()
          this.notificationService.error(error.message);
        }
      });
  }
}
