import { customerActions } from './actions';
import { GridViewType } from '../../../shared/components/grid/grid-config';
import { CustomerModel } from '../customer.model';

export const customersConfig: GridViewType<CustomerModel> = {
  columns: [
    {
      code: 'actions',
      isActionColumn: true,
      actions: customerActions
    },
    {
      code: 'companyName',
      label: 'Company Name',
    },
    {
      label: 'Account Number',
      code: 'companyAccountNumber'
    },
    {
      label: 'Last Name',
      code: 'contactLastName',
    },
    {
      label: 'First Name',
      code: 'contactFirstName',
    },
  ]
};
